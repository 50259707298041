import { toast } from 'react-toastify';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const defaultParams = {
  position: "bottom-center",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}

export const notifyToast = (text, type, params = {}) => {
  const options = { ...defaultParams, ...params }
  if (type === "error") {
    toast.error(text, options);
  } else if (type === 'info') {
    toast.info(text, options);
  } else {
    toast.success(text, options);
  }
}

export const trackEvent = (eventName, eventData) => {
  if (window.analytics) {
    window.analytics.track(eventName, {
      ...eventData,
      source: 'landing'
    })
  }
}

export const trackIdentifyAnonymous = (traits) => {
  if (window.analytics) {
    window.analytics.identify(traits)
  }
}

// https://segment.com/docs/connections/spec/identify/
export const trackIdentify = (userId, userEmail) => {
  if (window.analytics && window.analytics?.user) {
    const userHasIdAsEmail = window.analytics?.user()?.id()?.includes("@")
    if (userHasIdAsEmail) {
      window.analytics.alias(`${userId}`, userEmail)
    } 

    window.analytics.identify(`${userId}`)
  }
}

export const trackPage = (page) => {
  if (window.analytics) {
    window.analytics.page(page)
  }
}


export const useTranslationInternal = () => {
  const { t } = useTranslation();

  return t
}